import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Box, Heading1, Heading2, Heading3, Heading4 } from "@nulogy/components";
import { COPY_WIDTH } from "../../components/CONSTANTS";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Headings`}</h1>
    <p>{`There are four level of headings available to provide hierarchy in an application.`}</p>
    <Box maxWidth={COPY_WIDTH} mx="auto" mdxType="Box">
  <Heading1 mdxType="Heading1">Heading1</Heading1>
  <Heading2 mdxType="Heading2">Heading2</Heading2>
  <Heading3 mdxType="Heading3">Heading3</Heading3>
  <Heading4 mdxType="Heading4">Heading4</Heading4>
    </Box>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Heading1, Heading2, Heading3, Heading4 } from "@nulogy/components";

<Heading1>Heading 1</Heading1>
<Heading2>Heading 2</Heading2>
<Heading3>Heading 3</Heading3>
<Heading4>Heading 4</Heading4>
`}</code></pre>
    <h2>{`Props`}</h2>
    <p>{`Headings extend the `}<a parentName="p" {...{
        "href": "/components/Text"
      }}>{`Text`}</a>{` component and thus have access to all the same props.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      